import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";

const API_URL = 'http://localhost:8000/v1'

interface modalSliceProps {
  signUpModal: boolean;
  createInstitueModal: boolean;
  signInModal: boolean;
  otpModal: boolean;
  data: any;
  status: string;
  error: any;
}

const initialState: modalSliceProps = {
  signUpModal: false,
  createInstitueModal: false,
  signInModal: false,
  otpModal: false,
  data: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null
};

export const postData = async (data: any) => {
  // console.log("data ==>", data)
  const response = await axios({
    url: `${API_URL}/leads`,
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
  console.log('data ==>', response.data)
  return response.data;
};

const modalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    setSignupModal: (state, action: PayloadAction<boolean>) => {
      state.signUpModal = action.payload;
    },
    setCreateInstitueModal: (state, action: PayloadAction<boolean>) => {
      state.createInstitueModal = action.payload;
      // postData()
    },
    setSignInModal: (state, action: PayloadAction<boolean>) => {
      state.signInModal = action.payload;
    },
    setOtpModal: (state, action: PayloadAction<boolean>) => {
      state.otpModal = action.payload;
    },
    createLead: (state, action: PayloadAction<any>) => {
      // console.log("create lead", action.payload)
      postData(action.payload)
    }
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(postData.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(postData.fulfilled, (state, action) => {
  //       state.status = 'succeeded';
  //       state.data = action.payload;
  //     })
  //     .addCase(postData.rejected, (state, action) => {
  //       state.status = 'failed';
  //       state.error = action.error.message;
  //     });
  // }
});


export const {
  setSignupModal,
  setCreateInstitueModal,
  setSignInModal,
  setOtpModal,
  createLead
} = modalSlice.actions;

export default modalSlice.reducer;
